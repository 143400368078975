import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import store from './store'
import Skycon from "vue-skycons";
import { initializeApp } from 'firebase/app';

Vue.config.productionTip = false

Number.prototype.pad = function(size) {
  var s = String(this);
  while (s.length < (size || 2)) {s = "0" + s;}
  return s;
};

const firebaseConfig = {
  apiKey: "AIzaSyC_5l2hGQlNX662KYf-hr8_ytCsdEUm6lk",
  authDomain: "realtimethesis.firebaseapp.com",
  databaseURL: "https://realtimethesis.firebaseio.com",
  projectId: "realtimethesis",
  storageBucket: "realtimethesis.appspot.com",
  messagingSenderId: "1097391395895",
  appId: "1:1097391395895:web:00b04d4741965c07329bb2",
  measurementId: "G-MS9W1M17H7"
};

var app = initializeApp(firebaseConfig);

Vue.component("skycon", Skycon);
new Vue({
  vuetify,
  store,
  app,
  render: h => h(App)
}).$mount('#app')
