import axios from 'axios'
import Vuex from 'vuex'
import Vue from 'vue'
import { getDatabase, ref, get, child, onValue } from 'firebase/database';

Vue.use(Vuex);

const state = {
    timetable: [],
    weather: {},
    overlay: true
}


export const getters = {
    weather: (state) => {
        return state.weather
    }
}

const actions = {
    getCurrentTimetable({commit}) {
        axios({
            method: "get",
            headers: {},
            url: "api/current",
        }).then(response => {
            commit("SET_TIMETABLE", response.data);
            commit("SET_OVERLAY", false);
        })
    },

    async getCurrentWeather({commit}, date) {
        const weatherRef = ref(getDatabase());
        console.log(date);
        let weatherRefChild = child(weatherRef, "/weather/openweather/"+date)
        get(weatherRefChild).then((snapshot) => {
            if (snapshot.exists()) {
                var today_weather = snapshot.val();
                var keys = Object.keys(today_weather);
                var last_key = keys[keys.length-1];
                commit("SET_WEATHER", today_weather[last_key]);
            } else {
                console.log("No data");
            }
        }).catch((error) => {
            console.error(error);
        });

        onValue(weatherRefChild, (snapshot) => {
            if (snapshot.exists()) {
                var today_weather = snapshot.val();
                var keys = Object.keys(today_weather);
                var last_key = keys[keys.length-1];
                commit("SET_WEATHER", today_weather[last_key]);
            } else {
                console.log("No data");
            }
        });
    }
}

const mutations = {
    SET_TIMETABLE(state, timetable){
        state.timetable = timetable;
    },
    SET_WEATHER(state, weather){
        state.weather = weather;
    },
    SET_OVERLAY (state, overlay){
        state.overlay = overlay;
    }
}

export default new Vuex.Store({
    namespaced: true,
    state,
    getters,
    actions,
    mutations
})