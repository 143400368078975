<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row  class="row-small-header" style="height:116px; background-color:#1e4278; position:absolute; width:100%; top:0; z-index:1000">
          <v-col md="3" class="hidden-sm-and-down text-center pt-7">
            <h1 class="font-weight-bold mt-4" style="color:white">
              Raspored
            </h1>
          </v-col>
          <v-col sm="12" md="9">
            <v-row>
              <v-col cols="9">
                <h1 class="pt-md-2 font-weight-light text-small-header" style="color:white"><v-icon color="white">mdi-calendar-today</v-icon> <b>{{day}}</b> - {{date}}</h1>
              </v-col>
              <v-col cols="3">
                <h1 class="pt-md-2 font-weight-light text-right text-small-header" style="color:white"><v-icon color="white">mdi-clock-outline</v-icon> {{time}}</h1>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="fill-height">
          <v-col md="3" sm="12">
            <v-img class="mx-auto" style="width:60%" src="@/assets/fsre-logo.svg"></v-img>
            <h1 style="color:#1e4278; font-size:1.5rem; margin-top:1rem;">Vrijeme danas</h1>
            <v-row class="ma-0 pa-0">
              <v-col md="4" sm="12"  xs="12">
                <v-img :src="weather_icon" />
              </v-col>
              <v-col md="8" sm="12" xs="12">
                <h1 class="font-weight-bold" style="color:#1e4278;font-size:1rem">{{description}}</h1>
                <h1 class="font-weight-thin" style="color:#1e4278; font-size:4rem; line-height:4rem">{{temperature}} °C</h1>
                <v-divider></v-divider>
                <div class="d-flex justify-space-between">
                  <h4 class="font-weight-medium" style="color:#1e4278; font-size:1rem"><v-icon style="font-size:1rem" color="#1e4278">mdi-thermometer-chevron-down</v-icon> {{minimum}} °C</h4>
                  <h4 class="font-weight-medium" style="color:#1e4278; font-size:1rem"><v-icon style="font-size:1rem" color="#1e4278">mdi-thermometer-chevron-up</v-icon> {{maximum}} °C</h4>
                </div>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="d-flex justify-space-between">
                <h1 class="font-weight-medium" style="color:#1e4278; font-size:1.5rem; line-height:1.5rem"><v-icon style="font-size:1.5rem" color="#1e4278">mdi-weather-windy</v-icon>{{wind}} <span style="font-size:0.875rem">km/h</span></h1>
                <h1 class="font-weight-medium" style="color:#1e4278; font-size:1.5rem; line-height:1.5rem"><v-icon style="font-size:1.5rem" color="#1e4278">mdi-thermometer-lines</v-icon>{{pressure}}  <span style="font-size:0.875rem">hPa</span></h1>
                <h1 class="font-weight-medium" style="color:#1e4278; font-size:1.5rem; line-height:1.5rem"><v-icon style="font-size:1.5rem" color="#1e4278">mdi-water</v-icon>{{humidity}}  <span style="font-size:0.875rem">%</span></h1>
              </v-col>
            </v-row>

            <h1 style="color:#1e4278; font-size:1.5rem; margin-bottom:1rem">WiFi pristup</h1>
            <v-row>
              <v-col md=6 sm=12>
                <v-img src="@/assets/eduroam-logo.svg" />
              </v-col>
              <v-col md=6 sm=12>
                <v-row class="mb-md-5 mt-2">
                  <v-col md=2 class="text-center pa-0"><v-icon class="mx-auto" color="#1e4278">mdi-access-point</v-icon></v-col>
                  <v-col md=10 class="pa-0"><h3 class="font-weight-medium" style="color:#1e4278">FSRE</h3></v-col>
                  <v-col md=2 class="text-center pa-0"><v-icon class="mx-auto" color="#1e4278">mdi-key</v-icon></v-col>
                  <v-col md=10 class="pa-0"><h3 class="font-weight-medium" style="color:#1e4278">Fsre2018!</h3></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="9"  sm="12" style="z-index:2000">
            <ScheduleTable/>
            <v-img class="d-md-none" src="https://dnevnik.sum.ba/img/sumit-color.svg" />
          </v-col>
        </v-row>
        <v-img style="position:absolute; bottom:10px" class="d-none d-lg-block" src="https://dnevnik.sum.ba/img/sumit-color.svg" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ScheduleTable from './components/ScheduleTable';
import {mapGetters} from "vuex";

export default {
  name: 'App',
  components: {
    ScheduleTable,
  },

  data: () => ({
    interval: null,
    timer: null,
    time: null,
    day: null,
    date: null,
    days: ["Nedjelja", "Ponedjeljak", "Utorak", "Srijeda", "Četvrtak", "Petak", "Subota"]
  }),
  computed: {
    ...mapGetters({
      weather: "weather" 
    }),
    weather_icon(){
      if (this.weather.weather)
        return "http://openweathermap.org/img/wn/" + this.weather.weather.current.weather[0].icon + "@4x.png"
      return "http://openweathermap.org/img/wn/01d@4x.png"
    },
    description(){
      if (this.weather.weather) {
        console.log(this.weather.weather.current.temp);
        var desc = this.weather.weather.current.weather[0].description;
        return desc.charAt(0).toUpperCase() + desc.slice(1)
      }
      return ""
    },
    temperature(){
      if (this.weather.weather)
        return parseInt(this.weather.weather.current.temp);
      return 0
    },
    minimum(){
      if (this.weather.weather)
        return parseInt(this.weather.weather.daily[0].temp.min);
      return 0
    },
    maximum(){
      if (this.weather.weather)
        return parseInt(this.weather.weather.daily[0].temp.max);
      return 0
    },
    pressure(){
      if (this.weather.weather)
        return parseInt(this.weather.weather.current.pressure);
      return 0
    },
    humidity(){
      if (this.weather.weather)
        return parseInt(this.weather.weather.current.humidity);
      return 0
    },
    wind(){
      if (this.weather.weather)
        return this.weather.weather.current.wind_speed;
      return 0
    },
  },
  methods: {
    formatDate: function (date, format) {
      const map = {
          mm: (date.getMonth() + 1).pad(),
          dd: date.getDate().pad(),
          yyyy: date.getFullYear().pad()
      }
      return format.replace(/mm|dd|yyyy/gi, matched => map[matched])
    }
  }, 
  mounted() {
    document.title = "FSRE raspored"
    var self = this;
    var currentDate = this.formatDate(new Date(), "dd-mm-yyyy");
    this.$store.dispatch("getCurrentWeather", currentDate);
    this.interval = setInterval(function () {
      let current = new Date();
      self.time = current.getHours().pad() + ":" + current.getMinutes().pad() + ":" + current.getSeconds().pad();
      self.date = current.getDate().pad() + "." + (current.getMonth()+1).pad() + "." + current.getFullYear().pad()+".";
      self.day = self.days[current.getDay()];
    }, 1000);
  }
};
</script>
<style scoped>
@media screen and (max-width: 500px) {
    .text-small-header{
      font-size: 1rem !important;
    }
    .row-small-header {
      height: 50px !important;
      padding:7px
    }
}

</style>