<template>
  <v-layout column style="background-color: white" class="elevation-2 rounded-lg mt-sm-5 timetable">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-flex md12 style="overflow: auto; height:100%" class="rounded-lg">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th></th>
              <th class="text-left">
                Kolegij
              </th>
              <th class="text-left">
                Dvorana
              </th>
              <th class="text-left">
                Datum
              </th>
              <th class="text-left">
                Početak
              </th>
              <th class="text-left">
                Kraj
              </th>
            </tr>
          </thead>
          <tbody v-if="timetable.length == 0">
            <tr>
              <td colspan="6" class="text-center">Trenutno nema podataka u rasporedu.</td>
            </tr>
          </tbody>
          <tbody>
            <tr
              v-for="item in timetable"
              :key="item.course.id"
            >
              <td><div v-if="item.active" class="pulse"></div></td>
              <td class="font-weight-bold">{{ item.course.name }} <span v-if="item.groupname"> - Grupa({{item.groupname[0]}})</span></td>
              <td>{{ item.classroom.short }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.starttime }}</td>
              <td>{{ item.endtime }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-flex>
  </v-layout>
</template>

<script>
  export default {
    name: 'ScheduleTable',
    data: () => ({
      timer: null,
      interval: null,
      weatherTimer: null,
      time: null,
      date: null
    }),
    computed: {
      timetable() {
        return this.$store.state.timetable
      },
      overlay() {
        return this.$store.state.overlay
      }
    },
    mounted(){
      var self = this;
      self.$store.dispatch("getCurrentTimetable");
      this.timer = window.setInterval(
        function () {
          self.$store.dispatch("getCurrentTimetable");
        }, 60*1000);
    },
    destroyed(){
      window.clearInterval(this.timer);
      window.clearInterval(this.interval);
      window.clearInterval(this.weatherTimer);
    }
  }
</script>
<style scoped>

.pulse {
  margin:10px;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #2ccc4f;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(44, 204, 111, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(44, 204, 111, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(44, 204, 111, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(44, 204, 111, 0.4);
    box-shadow: 0 0 0 0 rgba(44, 204, 111, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(44, 204, 111, 0);
      box-shadow: 0 0 0 10px rgba(44, 204, 111, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(44, 204, 111, 0);
      box-shadow: 0 0 0 0 rgba(44, 204, 111, 0);
  }
}
</style>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

.v-application {
  font-family:  'Source Sans Pro', sans-serif !important;
}

th {
  font-size: 1.75rem !important;
}
  
td {
  color: rgba(0,0,0,0.6);
  font-size: 1.75rem !important;
}

@media screen and (max-width: 500px) {
    .timetable {
      margin-bottom: 10px !important;
    }
}

@media screen and (min-width: 500px) {
    .timetable {
      height: 92vh !important;
    }
}
</style>
